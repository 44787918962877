import Vue from 'vue'
import Vuex from 'vuex'

// Vue.use(Vuex)

export default new Vuex.Store({
    // 公共数据，随地可取
    state:{
        user: {},
        service: []
    },

    getters:{

    },
    // 定义方法，操作state里的数据,里面定义的方法第一个参数为state
    // 在组件中使用this.$store.commit('方法名','参数')调用
    mutations:{
        setUserInfo(state, user) {
            state.user = user
        },
        setService(state, service) {
          state.service = service
        }
    },
    // 定义异步操作数据的方法，定义的方法第一个参数是一个对象包含commit（调用mutations里的方法），dispatch(调用actions里的方法)，等。
    // 使用this.$store.dispatch('方法名')
    actions:{
        setInfo({ commit }, user) {
            commit("setUserInfo",user)
        },
        service({commit}, data){
            commit("setService",data)
        }
    },

})
