import {createRouter, createWebHashHistory} from "vue-router"

const routes = [
    {
        path: '/',
        component: () => import('@/components/index'),
        children: [
            {
                path: '/home',
                component: () => import('@/components/page/home/index')
            },
            {
                path: '/index',
                component: () => import('@/components/page/home/index')
            },
            {
                path: '',
                component: () => import('@/components/page/home/index')
            },
            {
                path: '/password',
                component: () => import('@/components/page/account/password')
            },
            {
                path: '/marketing',
                component: () => import('@/components/page/sms/marketing')
            },
            {
                path: '/business',
                component: () => import('@/components/page/sms/business')
            },
            {
                path: '/mms',
                component: () => import('@/components/page/sms/mms')
            },
            {
                path: '/videosms',
                component: () => import('@/components/page/sms/videosms')
            },
            {
                path: '/log',
                component: () => import('@/components/page/recharge/log')
            },
            {
                path: '/internationalcodesms',
                component: () => import('@/components/page/sms/internationalcodesms')
            },
            {
                path: '/dataInfo',
                component: () => import('@/components/page/recharge/report')
            },
            {
                path: '/detaillist',
                component: () => import('@/components/page/recharge/taskList'),
                meta:{keepAlive:true}
            },
            {
                path: '/dataDetail',
                component: () => import('@/components/page/recharge/dataDetail')
            },
            {
                path: '/addressbook',
                component: () => import('@/components/page/address/addressList')
            },
            {
                path: '/list',
                component: () => import('@/components/page/address/list')
            },
            {
                path: '/numDetail',
                component: () => import('@/components/page/address/numDetail')
            },
            {
                path: 'createVideoTemplate',
                component: () => import('@/components/page/sms/createVideoTemplate')
            },
            {
                path:'/sftpReport',
                component:() => import('@/components/page/recharge/sflreport')
            },
            {
                path: '/account',
                component:() => import('@/components/page/account/account')
            },
            {
                path:'/accountList',
                component:() => import('@/components/page/account/accountList')
            },
            {
                path:'/doc',
                component:() => import('@/components/page/other/doc')
            },
            {
                path:'/shortUrl',
                component:() => import('@/components/page/other/shortUrl')
            },
            {
                path:'/taskDetail',
                component:() => import('@/components/page/recharge/detailList.vue')
            }

        ]
    },
    {
        path: '/login',
        component: () => import('@/components/login')
    },
    {
        path:'/signIn',
        component:() => import('@/components/signIn')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router
