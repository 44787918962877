import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import api from './assets/js/http'
import globleData from './assets/js/globle'

const app = createApp(App)
app.use(router).use(store).use(Antd).mount('#app')
app.config.globalProperties.$api = api
app.config.globalProperties.$globleData = globleData
