import axios from 'axios'
import { message } from 'ant-design-vue'
import {msgHint} from './msgHint'
// const URL = 'https://sendapi.fulongzy.com/index/'
const URL = process.env.userapi_url || 'https://userapi.fulongzy.com/index/'
const SMSURL = process.env.smsapi_url || 'https://smsapi.fulongzy.com/index/'

function api(data) {
    let json = {
        con_id: data.login ? '' : localStorage.getItem('con_id') ? localStorage.getItem('con_id') : '',
        appid: this.$store.state.user.appid,
        appkey: this.$store.state.user.appkey
    }
    if (!data.params) {
        data.params = {}
    }
    if (!(data.params instanceof FormData)) {
        data.params = Object.assign(data.params, json)
    } else {
        data.params.append('appid', this.$store.state.user.appid)
        data.params.append('appkey', this.$store.state.user.appkey)
    }
    const instance = axios.create({
        baseURL: URL,
        timeout: 1000000,
        headers: {'Content-Type': 'application/json'}
    });
    // let loading = ElLoading.service()
    if (data.type === 'post' || !data.type) {
        let postUrl = ''
        if (data.domain == 'smsapi') {
            postUrl = SMSURL
        } else {
            postUrl = URL
        }
        instance.post(postUrl + data.url, data.params).then(res => {
            if (res.data.code == 200) {
                typeof data.success === 'function' ? data.success(res.data) : ''
            } else if (res.data.code == 5000) {
                message.error('请先登录')
                this.$router.push('/login')
            } else {
                typeof data.error === 'function' ? data.error(res.data) : error(res.data.code, res.data.msg, data.url)
            }
            // loading.close()
        }).catch(e => {
            network(e.response.status)
            // loading.close()
        })
    } else if (data.type === 'get') {
        instance.get(URL + data.url).then(res => {
            if (res.data.code == 200) {
                typeof data.success === 'function' ? data.success(res.data) : ''
            } else if (res.data.code == 5000) {
                message.error('请先登录')
                this.$router.push('/login')
            } else {
                typeof data.error === 'function' ? data.error(res.data) : error(res.data.code, res.data.msg, data.url)
            }
            // loading.close()
        }).catch(e => {
            network(e.response.status)
            // loading.close()
        })
    }
}

function network(code) {
    let text = ""
    switch (parseInt(code)) {
        case 201:
        case 202:
        case 203:
        case 204:
        case 205:
        case 206:
            text = "服务器无响应"
            break;
        case 400:
            text = "错误请求"
            break;
        case 401:
            text = "身份验证错误"
            break;
        case 403:
            text = "服务器拒绝请求"
            break;
        case 404:
        case 410:
            text = "404错误"
            break;
        case 405:
            text = "方法禁用"
            break;
        case 406:
            text = "不接受请求"
            break;
        case 407:
            text = "需要代理授权"
            break;
        case 408:
            text = "请求超时"
            break;
        case 409:
        case 411:
        case 412:
        case 415:
        case 416:
        case 417:
            text = "请求格式出错"
            break;
        case 413:
            text = "请求实体过大"
            break;
        case 414:
            text = "请求的URI过长"
            break;
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
            text = "内部错误" + code
            break;
        default:
            text = "网络错误"
    }
    message.error(text)
}

function error(code, msg, url) {
    msgHint(code, msg, url)
}

export default api
