import {message} from 'ant-design-vue'

let errCollect = {
    user: {
        delUserUpgoingTriggerRuler: {
            3001: 'id错误或记录不存在',
            3002: '规则正在启用中，无法删除'
        },
        restartUserUpgoingTriggerRuler: {
            3001: 'id错误或记录不存在',
            3002: '已经停用',
            3003: '有效期已过，无法启用',
            3004: '已经启用'
        },
        updateUserUpgoingTriggerRuler: {
            3000: '用户名或密码错误',
            3001: '短信签名应在2-20字之间',
            3002: '时间格式错误',
            3003: '时间格式错误',
            3004: '生效时间和结束时间不能一致',
            3005: '服务类型错误',
            3006: '修改数据错误'
        },
        addUserUpgoingTriggerRuler: {
            3000: '用户名或密码错误',
            3001: '短信签名应在2-20字之间',
            3002: '时间格式错误',
            3003: '时间格式错误',
            3004: '生效时间和结束时间不能一致',
            3005: '服务类型错误',
            3006: '已设置触发回复内容'
        },
        getUserUpgoingTriggerRuler: {
            3000: '用户名或密码错误',
            3003: '起始时间错误',
            3004: '截止时间错误'
        },
        getMobileReceipts: {
            3000: '用户名或密码错误',
            3003: '开始时间为空或者格式错误',
            3004: '结束时间为空或者格式错误',
            3005: '手机号码错误',
            3006: '最大查询间隔为一个月',
            3007: '未开通此项功能',
            3008: '无本月记录'
        },
        sendvercode: {
            3001: '手机格式错误',
            3002: '发送类型错误',
            3003: '一分钟内不能重复发送',
            3004: '发送失败',
            3000: '发送失败'
        },
        apportionSonUser: {
            3000: '用户不存在',
            3001: '手机号错误',
            3002: '缺少con_id',
            3003: 'con_id错误',
            3005: '请设置密码',
            3006: '该手机号已注册'
        },
        quicklogin: {
            3001: '手机号错误',
            3002: '账号不存在',
            3003: '密码错误',
            3004: '验证码格式错误',
            3005: '密码不合规',
            3006: '验证码错误',
            3007: '密码错误过多，请30分钟后重试',
            3008: '登录失败'
        },
        userRegistered: {
            3000: '手机号错误',
            3002: '密码强度不够',
            3003: '邮箱格式错误',
            3004: '验证码错误',
            3005: '手机号已注册',
            3006: '用户类型错误',
            3007: '用户名不能为空'
        },
        seetingUserEquities: {
            3001: '手机号错误',
            3002: '代理价格格式错误',
            3003: '母账户无该项服务',
            3004: '代理价格不能低于服务商价格',
            3005: '该项服务已添加过',
            3006: '子账户服务无法设置',
            3007: '服务不存在',
            3008: '该账户不是本账户的子账户'
        },
        login: {
            3001: '手机号错误',
            3002: '账号不存在',
            3003: '密码错误',
            3004: '登陆失败',
            3005: '密码强度不够'
        },
        recordUserQualification: {},
        getUser: {
            3000: '用户不存在',
            3001: 'con_id长度错误',
            3002: '缺少con_id',
            3003: 'con_id错误'
        },
        resetpassword: {
            3001: '手机格式错误',
            3002: '手机号未注册',
            3003: '更新失败',
            3004: '验证码错误',
            3005: '密码强度不够',
            3006: '验证码错误'
        },
        allocateAgentNumber: {
            3002: '余额不足',
            3004: '目标账户没有该服务'
        },
        getUserMultimediaMessageTask: {
            3003: '用户不存在'
        },
        getUserSubmitTask: {
            3003: '用户不存在'
        },
        getUserBusinessSubmitTask: {
            3003: '用户不存在'
        },
    },
    send: {
        getSmsInternationalTask: {
            3000: "appid或appkey错误",
            3001: '提交号码为空',
            3003: '发送时间错误',
            3004: '发送时间错误',
            3005: "请选择发送国家"
        },
        upGoing: {
            3000: '用户名密码错误'
        },
        getMobilesDetail: {
            3001: '号码为空'
        },
        getSmsBuinessMsgId: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '用户没有此服务',
            3004: '此服务被停用',
            3005: '此签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: "手机号错误",
            3009: '发生时间格式错误',
            3011: '拓展码格式错误',
            3010: '预约发送时间小于当前时间',
            3012: '签名长度为2-10个字符',
            3013: '短信内容包含敏感词',
            3014: '短信内容为空或者短信内容超过500字符',
            3026: '该签名不支持当前服务',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        getSmsMarketingTask: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '用户没有此服务',
            3004: '此服务被停用',
            3005: '此签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: "手机号错误",
            3009: '发生时间格式错误',
            3011: '拓展码格式错误',
            3010: '预约发送时间小于当前时间',
            3012: '签名长度为2-10个字符',
            3013: '短信内容包含敏感词',
            3014: '短信内容为空或者短信内容超过500字符',
            3026: '该签名不支持当前服务',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        getSmsMultimediaMessageTask: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3024: '请选择模板',
            3025: '报备未通过',
            3011: '拓展码格式错误',
            3026: '签名不存在',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        multimediaTemplateSignatureReport: {
            3000: '用户名或密码错误',
            3001: '手机格式错误',
            3002: '单批次手机号码为空',
            3003: '发送时间格式错误',
            3004: '预定发送时间小于当前时间',
            3005: '该账户没有此服务',
            3006: '余额不足',
            3007: '短信标题不能为空',
            3008: '无效的图片',
            3009: '彩信长度超过100KB或内容为空',
            3010: '图片未上传',
            3011: '内部错误：3011'
        },
        submitTemplateMultimediaMessage: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3024: '请选择模板',
            3025: '报备未通过',
            3029: '该模板为变量模板',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        submitBatchCustomMultimediaMessage:{
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3024: '请选择模板',
            3025: '报备未通过',
            3028: '发送失败',
            3029: '该模板为变量模板',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        SignatureReport: {
            3000: 'appid 或者appkey 为空', 3001: '业务场景错误', 3002: '签名长度小于2个字', 3003: '该用户没有此项服务'
        },
        submitBatchCustomMarketing: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3009: '发送时间格式错误',
            3010: '预约时间小于当前时间',
            3011: '拓展码格式错误',
            3022: '未获取变量内容',
            3026: '签名不存在',
            3027: '模板不存在',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        submitBatchCustomMarketingTerrace: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3009: '发送时间格式错误',
            3010: '预约时间小于当前时间',
            3011: '拓展码格式错误',
            3022: '未获取变量内容',
            3026: '签名不存在',
            3027: '模板不存在',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        submitBatchCustomBusiness: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3009: '发送时间格式错误',
            3010: '预约时间小于当前时间',
            3011: '拓展码格式错误',
            3022: '未获取变量内容',
            3026: '签名不存在',
            3027: '模板不存在',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        submitBatchCustomBusinessTerrace: {
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3009: '发送时间格式错误',
            3010: '预约时间小于当前时间',
            3011: '拓展码格式错误',
            3022: '未获取变量内容',
            3026: '签名不存在',
            3027: '模板不存在',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        textTemplateSignatureReport: {
            3000: 'appid 或者appkey 为空',
            3001: '业务场景错误',
            3002: '签名长度小于2个字',
            3003: '该用户没有此项服务'
        },
        submitTemplateSupMessage:{
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3024: '请选择模板',
            3025: '模板未通过',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        },
        submitTemplateVarSupMessage:{
            3000: '用户名或密码错误',
            3001: '用户不存在',
            3002: '用户暂停服务',
            3003: '没有此项服务',
            3004: '服务被停用',
            3005: '签名审核未通过',
            3006: '没有审核通过的模板',
            3007: '签名拓展码已被使用',
            3008: '手机号或内容无效',
            3024: '请选择模板',
            3025: '模板未通过',
            3030: '自审任务保存失败',
            3031: '获取环境变量失败',
            3032: 'nsq错误',
            3033: '获取json为空'
        }
    },
    upload: {
        uploadUserExcel: {
            3001: '上传文件不能为空',
            3002: '上传失败',
            3003: '上传号码为空'
        }
    },
    mobile: {
        useraddblacklist: {
            3000: '意外错误，添加失败',
            3001: '登录异常，查不到该用户',
            3002: '号码错误',
            3003: '用户服务错误',
            3004: '业务ID错误',
            3005: '没有有效电话',
            3006: '未知号段，添加失败'
        }
    }
}

function msgHint(code, msg, url = '') {
    if (!url) throw new Error('url未传入');
    let port = url.split('/');
    let text = '';
    if (msg) {
        text = msg
    } else if (errCollect[port[0]] && errCollect[port[0]][port[1]] && errCollect[port[0]][port[1]][code]) {
        text = errCollect[port[0]][port[1]][code]
    } else {
        text = '错误码：' + code
    }
    message.error(text)
}

export {
    msgHint
}
